import React from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Typography,
  useTheme,
  CardActionArea,
  List,
  ListItem,
  Divider,
} from "@mui/material";

const isDesktop = window.matchMedia("(min-width: 768px)").matches;

const NavItems = ({ pages }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: {
          xs: "250px",
          md: "250px",
        },
        mx: "16px",
      }}
    >
      <List>
        {pages.map((page, index) => (
          <div key={page.path}>
            <ListItem
              component={Link}
              to={page.path}
              elevation={isDesktop ? 1 : 0}
              sx={{
                color: {
                  xs: theme.palette.primary.main,
                  md: theme.palette.primary.main,
                },
                backgroundColor: theme.palette.secondary.main,
              }}
            >
              <CardActionArea sx={{ padding: "8px 16px" }}>
                <Typography variant="body2">{page.title}</Typography>
              </CardActionArea>
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    </Box>
  );
};

export default NavItems;
