import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Typography, Grid, List, ListItem } from "@mui/material";

import * as shopActions from "../../../context/shop/shopActions";
import PageBase from "../PageBase";

import styled from "@emotion/styled";

const Img = styled.img`
  width: 100%;
`;

const Detail = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const product = useSelector((state) => state.products.product);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadProducts = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      await dispatch(shopActions.getProductById(id));
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }, [dispatch, setLoading, setError, id]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  if (error) {
    return (
      <PageBase>
        <Typography variant="h3">Error</Typography>
      </PageBase>
    );
  }

  if (loading) {
    return (
      <PageBase>
        <Typography variant="h3">Loading...</Typography>
      </PageBase>
    );
  }

  const { title, price, desc, img } = product;

  return (
    <PageBase>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Img src={img} />
        </Grid>
        <Grid item md={8}>
          <List>
            <ListItem>
              <Typography variant="h3">{title}</Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1">
                £{price && price.toFixed(2)}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1">{desc}</Typography>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </PageBase>
  );
};

export default Detail;
