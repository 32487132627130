import { SET_PRODUCTS, SET_SINGLE_PRODUCT } from "./shopActions";

const initialState = {
  products: [],
  product: {},
};
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.products,
      };
    case SET_SINGLE_PRODUCT:
      return {
        ...state,
        product: action.product,
      };

    default:
      return state;
  }
};
