import Product from "../../models/product";

import { database } from "../../storage/initialize";
import { ref, get } from "firebase/database";

export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_FOCUS_PRODUCT = "SET_FOCUS_PRODUCT";
export const SET_SINGLE_PRODUCT = "SET_SINGLE_PRODUCT";

export const fetchProducts = () => {
  return async (dispatch) => {
    try {
      const productsRef = ref(database, "products");
      const dataResponse = await get(productsRef);
      const data = dataResponse.val();

      let products = [];
      for (const key in data) {
        products.push(
          new Product(
            key,
            data[key].title,
            data[key].image.url,
            data[key].desc,
            data[key].price
          )
        );
      }
      dispatch({ type: SET_PRODUCTS, products });
    } catch (err) {
      // send to analytics
      throw err;
    }
  };
};

export const setFocusProduct = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_FOCUS_PRODUCT, id });
  };
};

export const getProductById = (id) => {
  return async (dispatch) => {
    try {
      const productRef = ref(database, `products/${id}`);
      const dataResponse = await get(productRef);
      const data = dataResponse.val();

      const { title, desc, image, price } = data;
      const product = new Product(id, title, image.url, desc, price);
      dispatch({ type: SET_SINGLE_PRODUCT, product });
    } catch (err) {
      // send to analytics
      throw err;
    }
  };
};
