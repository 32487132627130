import React from "react";
import styled from "@emotion/styled";

import { Typography, useTheme } from "@mui/material";

import NewsletterSignup from "../../features/general/NewsletterSignup";

const Maintenance = () => {
  const theme = useTheme();
  const PageContainer = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.secondary.main};
  `;
  return (
    <PageContainer>
      <Typography variant="h1">cnnct card</Typography>
      <Typography variant="body">
        Enter your email below to stay cnnctd
      </Typography>
      <NewsletterSignup />
    </PageContainer>
  );
};

export default Maintenance;
