import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "./components/pages/Home/Home";
import About from "./components/pages/About/About";
import Store from "./components/pages/Store/Store";
import Detail from "./components/pages/Store/Details";
import Contact from "./components/pages/Contact/Contact";
import NotFound from "./components/pages/NotFound/NotFound";
import Maintenance from "./components/pages/Maintenance/Maintenance";
import Test from "./components/pages/Test/Test";
import Auth from "./components/pages/Authentication/Auth";

import Navbar from "./components/layout/Navigation/Navbar";
import ScrollTop from "./components/layout/Navigation/ScrollTop";

import productReducer from "./context/shop/shopReducer";
import authReducer from "./context/auth/authReducer";

import { CssBaseline, ThemeProvider, Box } from "@mui/material";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";

import { IKContext } from "imagekitio-react";

import theme from "./theme/Theme";

const rootReducer = combineReducers({
  products: productReducer,
  auth: authReducer,
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

const pages = [
  { path: "/", title: "Home" },
  { path: "/about", title: "About" },
  { path: "/contact", title: "Contact" },
  { path: "/store", title: "Store" },
  { path: "/login", title: "Login" },
];

let isDev = process.env.REACT_APP_IS_DEV;

function App() {
  const publicKey = "public_0iD/w/eLGLz3H605u4W7eVYmdT4=";
  const urlEndpoint = "https://ik.imagekit.io/cnnct";
  const authenticationEndpoint =
    "https://cnnctcard.com/.netlify/functions/auth";

  if (1) {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Maintenance />
        </ThemeProvider>
      </Provider>
    );
  }
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <IKContext
          publicKey={publicKey}
          urlEndpoint={urlEndpoint}
          transformationPosition="path"
          authenticationEndpoint={authenticationEndpoint}
        >
          <Router>
            <Box id="back-to-top-anchor" />
            <Navbar title="cnnct." pages={pages} />
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/about">
                <About />
              </Route>
              <Route exact path="/store">
                <Store />
              </Route>
              <Route path="/store/:id">
                <Detail />
              </Route>
              <Route exact path="/contact">
                <Contact />
              </Route>
              <Route exact path="/test">
                <Test />
              </Route>
              <Route exact path="/login">
                <Auth />
              </Route>
              <Route component={NotFound} />
            </Switch>
            <ScrollTop anchorSelector="back-to-top-anchor">
              <Fab
                color="secondary"
                size="small"
                aria-label="scroll back to top"
              >
                <KeyboardArrowUpIcon />
              </Fab>
            </ScrollTop>
          </Router>
        </IKContext>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
