// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getAuth } from "@firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDZjehilZO3RHg-xuSfIh7qqLuTlpktHu8",
  authDomain: "cnnct-6f24c.firebaseapp.com",
  databaseURL:
    "https://cnnct-6f24c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "cnnct-6f24c",
  storageBucket: "cnnct-6f24c.appspot.com",
  messagingSenderId: "1045710318127",
  appId: "1:1045710318127:web:59581978164bdffd3b74da",
  measurementId: "G-VD5SKZ884V",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const database = getDatabase(app);
