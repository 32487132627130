import React from "react";

import { Typography, Grid, Box } from "@mui/material";
import { styled } from "@mui/system";

const Img = styled("img")({
  width: "100%",
  objectFit: "cover",
  height: "300px",
});

const StepImage = styled(Box)({ marginTop: "auto" });
const StepContainer = styled(Grid)({
  display: "flex",
  flexDirection: "column",
});
const StepContent = styled(Box)({ marginBottom: "32px" });

const Steps = () => {
  return (
    <Grid container spacing={2}>
      <StepContainer xs={12} md={6} lg={4} item>
        <Typography variant="h3">Step 1.</Typography>
        <StepContent>
          <Typography variant="body">Pick up a cnnct product</Typography>
        </StepContent>
        <StepImage>
          <Img src="https://images.unsplash.com/photo-1612103198005-b238154f4590?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2669&q=80" />
        </StepImage>
      </StepContainer>
      <StepContainer xs={12} md={6} lg={4} item>
        <Typography variant="h3">Step 2.</Typography>
        <StepContent>
          <Typography variant="body">
            Sign up and create your personalised page
          </Typography>
        </StepContent>
        <StepImage>
          <Img src="https://images.unsplash.com/photo-1509395286499-2d94a9e0c814?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1760&q=80" />
        </StepImage>
      </StepContainer>
      <StepContainer xs={12} md={6} lg={4} item>
        <Typography variant="h3">Step 3.</Typography>
        <StepContent>
          <Typography variant="body">Start cnncting</Typography>
        </StepContent>
        <StepImage>
          <Img src="https://images.unsplash.com/photo-1556742111-a301076d9d18?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80" />
        </StepImage>
      </StepContainer>
    </Grid>
  );
};

export default Steps;
