import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  updateProfile,
} from "firebase/auth";
import { auth } from "../../storage/initialize";

export const AUTHENTICATE = "AUTHENTICATE";
export const LOGOUT = "LOGOUT";

const handleError = (errorCode) => {
  let error = { ok: false };
  switch (errorCode) {
    case "auth/email-already-in-use":
      error["message"] = "Email is already in use";
      break;
    case "auth/invalid-email":
      error["message"] = "Please enter a valid email";
      break;
    default:
      error["message"] = "Something went wrong! Please try again.";
  }
  return error;
};

export const authenticate = (userId, token, expiryTime) => {
  return (dispatch) => {
    dispatch({ type: AUTHENTICATE, userId, token });
  };
};

export const signup = (email, password, name) => {
  return async (dispatch) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        updateProfile(auth.currentUser, {
          displayName: name,
        })
          .then(() => {
            const token = user.accessToken;
            //const expiresIn = user.stsTokenManager.expirationTime;
            //const refreshToken = user.stsTokenManager.refreshToken;
            const userId = user.uid;
            dispatch(authenticate(userId, token));
            return { ok: true, message: "Signed in!" };
          })
          .catch((error) => {
            const errorCode = error.code;
            return handleError(errorCode);
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        return handleError(errorCode);
      });
  };
};

export const signin = (email, password, name) => {
  return async (dispatch) => {
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        const token = user.accessToken;
        //const expiresIn = user.stsTokenManager.expirationTime;
        //const refreshToken = user.stsTokenManager.refreshToken;
        const userId = user.uid;
        dispatch(authenticate(userId, token));
        return { ok: true, message: "Signed in!" };
      })
      .catch((error) => {
        const errorCode = error.code;
        return handleError(errorCode);
      });
  };
};

export const signinGoogle = () => {
  return async (dispatch) => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        const userId = user.uid;

        dispatch(authenticate(userId, token));
        return { ok: true, message: "Signed in!" };
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        return handleError(errorCode);
      });
  };
};

export const signout = () => {
  return async (dispatch) => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        dispatch({ type: LOGOUT });
      })
      .catch((error) => {
        // An error happened.
      });
  };
};
