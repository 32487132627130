import React from "react";
import { Box, TextField, Button, useTheme } from "@mui/material";

const NewsletterSignup = () => {
  const theme = useTheme();

  return (
    <Box
      component="form"
      action="https://cnnctcard.us5.list-manage.com/subscribe/post"
      method="POST"
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <input type="hidden" name="u" value="7a8a28797b12f61ab6258ecfa"></input>
      <input type="hidden" name="id" value="185cbb6d5d"></input>

      <TextField
        sx={{
          backgroundColor: theme.palette.secondary.main,
          borderRadius: "4px",
        }}
        label="Your e-mail"
        variant="filled"
        margin="normal"
        type="email"
        autoCapitalize="off"
        autoCorrect="off"
        name="MERGE0"
        id="MERGE0"
        size="25"
      />

      <Button type="submit" name="submit" variant="contained" color="secondary">
        Sign Up
      </Button>
      <input
        type="hidden"
        name="ht"
        value="1c932ccb01bc4341ae0d201e95ce56fba30851a2:MTYzMjM0MjAyNC4yNDk0"
      ></input>
      <input type="hidden" name="mc_signupsource" value="hosted"></input>
    </Box>
  );
};

export default NewsletterSignup;
