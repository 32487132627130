import { createTheme, responsiveFontSizes } from "@mui/material";
const theme = createTheme({
  typography: {
    fontFamily: ["Quicksand"].join(","),
    h1: {
      fontFamily: "BeVietnam", // Change a specific variant
    },
    h2: {
      fontFamily: "BeVietnam", // Change a specific variant
    },
    h3: {
      fontFamily: "BeVietnam", // Change a specific variant
    },
    h4: {
      fontFamily: "BeVietnam", // Change a specific variant
    },
    h5: {
      fontFamily: "BeVietnam", // Change a specific variant
    },
    h6: {
      fontFamily: "BeVietnam", // Change a specific variant
    },
  },
  palette: {
    primary: {
      main: "#1E96FC",
      dark: "#345e83",
    },
    secondary: {
      main: "#EDF2F4",
      dark: "#bdc1c3",
    },
    accent: {
      main: "#173753",
    },
    background: {
      paper: "#EDF2F4",
      default: "#EDF2F4",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
  },
});

export default responsiveFontSizes(theme);
