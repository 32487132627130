import React from "react";

import { Drawer } from "@mui/material";
import { styled } from "@mui/system";
import NavItems from "./NavItems";

const DrawerNavContainer = styled("div")({
  margin: "auto 0",
});

const NavDrawer = ({ toggle, pages, open }) => {
  return (
    <Drawer open={open} onClose={toggle(false)}>
      <DrawerNavContainer
        role="presentation"
        onClick={toggle(false)}
        onKeyDown={toggle(false)}
      >
        <NavItems pages={pages} />
      </DrawerNavContainer>
    </Drawer>
  );
};

export default NavDrawer;
