import { AUTHENTICATE, LOGOUT } from "./authActions";
import { onAuthStateChanged } from "@firebase/auth";
import { auth } from "../../storage/initialize";

const initialState = {
  token: null,
  userId: null,
};

const authReducer = (state = initialState, action) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      const token = user.accessToken;
      return {
        token: token,
        userId: uid,
      };
    } else {
      return {
        token: null,
        userId: null,
      };
    }
  });

  switch (action.type) {
    case AUTHENTICATE:
      return {
        token: action.token,
        userId: action.userId,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
